import { __awaiter, __generator } from "tslib";
// @i18n-ignore-all
import { app as api } from '@ekuaibao/whispered';
import actions from './dimension-map.action';
export var Standard_Unique_Key = 'dimensionMap.pc';
export default [
    {
        id: '@dimension-map',
        path: '/dimension-map',
        ref: '/',
        reducer: function () { return Promise.resolve(actions.getReducer()); },
        onload: function () { return import('./dimension-map-view'); },
        onfirst: function (app) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, app.store
                            .dynamic('@dimension-map', function () { return import('./dimension-map.store'); })
                            .load('@dimension-map')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        'get:role:list': function (cb) {
            return api.store.dispatch('@dimension-map/GetroleGroups')(cb);
        },
        'get:type:list': function () {
            return api.store.dispatch('@dimension-map/GetTypes')();
        },
        store: function () { return import('./dimension-map.store'); },
    },
    {
        point: '@@menus',
        onload: function () { return [
            {
                id: 'dimension-map',
                pId: 'enterprise-manage',
                permissions: ['SYS_ADMIN'],
                weight: 10,
                label: '档案关系',
                powers: ['CustomFileRole'],
                href: '/dimension-map',
                icon: 'role-manage',
            },
        ]; },
    },
    {
        point: '@@layers',
        prefix: '@dimension-map',
        onload: function () { return import('./layers'); },
    },
    {
        resource: '@dimension-map',
        value: {
            dimensionMapUtils: require('./utils/util')
        }
    }
];
