import { __assign, __read, __spread } from "tslib";
import { app } from '@ekuaibao/whispered';
import { Standard_Unique_Key } from '../index';
export function requestBoyd(values) {
    var purposeType = values.purposeType, sourceType = values.sourceType;
    var obj = { purposeType: purposeType, sourceType: sourceType, purposeTypeValue: '', sourceTypeValue: '' };
    if (purposeType.startsWith('basedata.Dimension')) {
        obj.purposeType = 'basedata.Dimension';
        obj.purposeTypeValue = values.purposeType;
    }
    if (sourceType.startsWith('basedata.Dimension')) {
        obj.sourceType = 'basedata.Dimension';
        obj.sourceTypeValue = values.sourceType;
    }
    if (purposeType.startsWith('datalink.DataLinkEntity')) {
        obj.purposeType = 'datalink.DataLinkEntity';
        obj.purposeTypeValue = values.purposeType;
    }
    if (sourceType.startsWith('datalink.DataLinkEntity')) {
        obj.sourceType = 'datalink.DataLinkEntity';
        obj.sourceTypeValue = values.sourceType;
    }
    return __assign(__assign({}, values), obj);
}
export function filterRole(Rolelist, option) {
    if (option === 'all') {
        return {
            searchData: Rolelist,
            searchOpenKeys: [],
        };
    }
    return roleMapFilterByType(Rolelist, option);
}
function roleMapFilterByType(Rolelist, option) {
    // tslint:disable-next-line: one-variable-per-declaration
    var searchData = [], searchOpenKeys = [];
    Rolelist.forEach(function (line) {
        var searchRoleDefs = [];
        if (line.roleDefs && line.roleDefs.length) {
            searchRoleDefs = line.roleDefs.filter(function (item) { return filterByType(item, option); });
        }
        if (searchRoleDefs.length) {
            line.roleDefs = searchRoleDefs;
            searchData.push(line);
            searchOpenKeys.push(line.id);
        }
    });
    return {
        searchData: searchData,
        searchOpenKeys: searchOpenKeys,
    };
}
function filterByType(item, option) {
    var sourceType = item.sourceType.split('.')[1];
    var purposeType = item.purposeType.split('.')[1];
    if (option === sourceType || purposeType === option) {
        return true;
    }
    return false;
}
export function searchList(list, str) {
    var searchData = [], searchOpenKeys = [];
    list.forEach(function (line) {
        var searchRoleDefs = [];
        if (line.roleDefs && line.roleDefs.length) {
            searchRoleDefs = line.roleDefs.filter(function (item) { return !!~item.name.indexOf(str) || !!~item.code.indexOf(str); });
        }
        if (searchRoleDefs.length) {
            line.roleDefs = searchRoleDefs;
            searchData.push(line);
            searchOpenKeys.push(line.id);
        }
    });
    return {
        searchData: searchData,
        searchOpenKeys: searchOpenKeys,
    };
}
export function getRoleDefName(roledef, typeList) {
    var purposeType = roledef.purposeType, sourceType = roledef.sourceType, purposeTypeValue = roledef.purposeTypeValue, sourceTypeValue = roledef.sourceTypeValue;
    var purpose = getDimensionName(purposeType.split('.')[1], typeList, purposeTypeValue);
    var source = getDimensionName(sourceType.split('.')[1], typeList, sourceTypeValue);
    return i18n.get("{__k0}\u548C{__k1}", { __k0: source, __k1: purpose });
}
function getDimensionName(typeName, typeList, typeValue) {
    var map;
    (function (map) {
        map[map["Dimension"] = i18n.get('档案')] = "Dimension";
        map[map["Department"] = i18n.get('部门')] = "Department";
        map[map["Staff"] = i18n.get('员工')] = "Staff";
        map[map["FeeType"] = i18n.get('费用类型')] = "FeeType";
        map[map["PayeeInfo"] = i18n.get('收款信息')] = "PayeeInfo";
    })(map || (map = {}));
    if (typeName === 'Dimension' || typeName === 'DataLinkEntity') {
        var obj = typeList === null || typeList === void 0 ? void 0 : typeList.find(function (v) { return v.code === typeValue; });
        return obj ? obj.name : '';
    }
    return map[typeName];
}
export function isEditable(code, sourceType, purposeType, type) {
    if (type === 'sourceType') {
        return ((purposeType === 'pay.PayeeInfo' || purposeType === 'flow.FeeType') &&
            (code === 'flow.FeeType' || code === 'pay.PayeeInfo'));
    }
    if (type === 'purposeType') {
        return ((sourceType === 'pay.PayeeInfo' || sourceType === 'flow.FeeType') &&
            (code === 'flow.FeeType' || code === 'pay.PayeeInfo'));
    }
    return false;
}
function findNext(list, result) {
    if (result === void 0) { result = []; }
    list.forEach(function (v) {
        result.push(v.id);
        if (v.children.length) {
            findNext(v.children, result);
        }
    });
    return result;
}
export function findPrev(list, feeTypeList, result, parentId, id) {
    if (result === void 0) { result = []; }
    list.forEach(function (v) {
        // const obj = v.children.find((v: any) => v.id === id)
        if (parentId && parentId === v.id) {
            result.push(v.id);
            findPrev(feeTypeList, feeTypeList, result, v.parentId, id);
        }
        else if (v.id === id && v.children.length) {
            result.push.apply(result, __spread(findNext(v.children)));
        }
        else if (v.children.length) {
            findPrev(v.children, feeTypeList, result, parentId, id);
        }
    });
    return result;
}
export function formatDataForGroup(data) {
    if (data === void 0) { data = []; }
    var showFunctionByKey = app.require('@elements/StandardVersionComponent').showFunctionByKey;
    var entityMap = {
        'organization.Staff': i18n.get('员工'),
        'organization.Department': i18n.get('部门'),
        'flow.FeeType': i18n.get('费用类型'),
        'pay.PayeeInfo': i18n.get('收款信息'),
        'basedata.Dimension': i18n.get('自定义档案'),
        'datalink.DataLinkEntity': i18n.get('业务对象'),
    };
    var map = {};
    data.forEach(function (item) {
        var splits = item.code.split('.');
        if (splits.length > 2) {
            splits.pop();
        }
        var entity = splits.join('.');
        var group = map[entity];
        if (!group) {
            group = { label: entityMap[entity], items: [], entity: entity };
            map[entity] = group;
        }
        group.items.push(item);
    });
    if (!showFunctionByKey(Standard_Unique_Key + ".datalink")) {
        delete map['datalink.DataLinkEntity'];
    }
    return Object.values(map) || [];
}
var dimensionMapMark = 'dimension:map:import';
// 控制档案关系导入状态视图是否需要展示
export function setLocal(id, flag) {
    if (flag === void 0) { flag = false; }
    var str = "" + dimensionMapMark + id;
    flag ? localStorage.setItem(str, JSON.stringify(flag)) : localStorage.removeItem(str);
}
export function getLocal(id) {
    var str = "" + dimensionMapMark + id;
    return JSON.parse(localStorage.getItem(str));
}
export default {
    requestBoyd: requestBoyd,
    searchList: searchList,
    findPrev: findPrev,
    formatDataForGroup: formatDataForGroup,
};
