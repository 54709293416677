import { __decorate, __extends } from "tslib";
import { Actions, action, node, reducer } from '@ekuaibao/store';
import { ID } from './key';
import { Resource } from '@ekuaibao/fetch';
var archivaRelations = new Resource('/api/v1/basedata/dimensions/archivaRelations');
var DimensionMapActions = /** @class */ (function (_super) {
    __extends(DimensionMapActions, _super);
    function DimensionMapActions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DimensionMapActions.prototype.getDefaultPayee = function () {
        return {
            payload: archivaRelations.GET(''),
        };
    };
    DimensionMapActions.pluginName = ID;
    __decorate([
        reducer(function (action) { var _a, _b; return (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []; }),
        node('relationTypeList', []),
        action
    ], DimensionMapActions.prototype, "getDefaultPayee", null);
    return DimensionMapActions;
}(Actions));
export var actions = new DimensionMapActions();
export var dimensionMapActions = actions;
export default actions;
